import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import Loader from './Loader'; 
const ContactSection = () => {
  const contactRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Hide loader after a delay
      gsap.from(contactRef.current, {
        opacity: 0,
        y: 20,
        duration: 1,
        ease: 'power2.out',
      });
    }, 2000); // Adjust the duration as needed

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  if (loading) {
    return <Loader loading={loading} />; // Show loader while loading
  }

  return (
    <section id="contact" ref={contactRef}>
      <div className="container">
        <h2 className="section-title">Contact Me</h2>
        <div className="contact-wrapper">
          <a
            rel="noreferrer"
            target="_blank"
            className="cta-btn cta-btn--resume" 
            href="mailto:iamthishanth@gmail.com"
          >
            <i className="fas fa-envelope" style={{ marginRight: '8px' }}></i>
            Email Me
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
