import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHtml5, faCss3Alt, faJs, faReact, faNode, faLaravel, faGithub } from "@fortawesome/free-brands-svg-icons"; // Import FontAwesome brand icons
import { faServer } from "@fortawesome/free-solid-svg-icons"; // Import FontAwesome solid icons
import { Container, Row, Col, Card } from "react-bootstrap"; // Import Bootstrap components

const SkillsSection = () => {
  const skillElements = useRef([]);

  const skills = [
    {
      title: "HTML & CSS",
      icon: faHtml5, // HTML icon
      color: "#E44D26", // HTML color
    },
    {
      title: "VPS Management and Hosting",
      icon: faServer, // VPS Management icon
      color: "#68A063", // Color for VPS Management
    },
    {
      title: "React",
      icon: faReact, // React icon
      color: "#61DAFB", // React color
    },
    {
      title: "Node.js & Express",
      icon: faNode, // Node.js icon
      color: "#68A063", // Node.js color
    },
    {
      title: "Laravel",
      icon: faLaravel, // Laravel icon
      color: "#FF2D20", // Laravel color
    },
    {
      title: "Git & GitHub",
      icon: faGithub, // Git & GitHub icon
      color: "#211F1F", // Git & GitHub color
    },
  ];

  useEffect(() => {
    // GSAP animation for skill elements
    gsap.from(skillElements.current, {
      duration: 1.2,
      opacity: 0,
      y: 30,
      scale: 0.95,
      stagger: 0.25,
      ease: "power3.out",
    });
  }, []);

  return (
    <section id="skills">
      <Container>
        <h2 className="section-title text-center">Skills</h2>
        <Row>
          {skills.map((skill, index) => (
            <Col lg={4} md={6} className="mb-4" key={index} ref={(el) => (skillElements.current[index] = el)}>
              <Card className="text-center shadow-sm h-100" style={{ borderRadius: "15px", transition: "transform 0.3s, box-shadow 0.3s" }}>
                <Card.Body>
                  <FontAwesomeIcon 
                    icon={skill.icon} 
                    size="5x" // Increased icon size
                    style={{ color: skill.color }} // Inline style for icon color
                    className="mb-3" 
                  />
                  <Card.Title>{skill.title}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <style jsx>{`
        .card:hover {
          transform: scale(1.05); // Scale up on hover
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); // Add shadow on hover
        }
      `}</style>
    </section>
  );
};

export default SkillsSection;
