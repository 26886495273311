import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const HeroSection = ({ loading }) => {
  const hRef = useRef(null); // Ref for "This is Thishanth"
  const hiRef = useRef(null); // Ref for "Hi" text
  const exclamationRef = useRef(null); // Ref for "!"
  const progressRef = useRef(null); // Ref for the progress bar

  useEffect(() => {
    if (!loading) {
      // Trigger animations only after loading is complete
      const animateText = () => {
        gsap.fromTo(
          hiRef.current,
          { opacity: 0, y: -50, scale: 0.8, rotation: 5 }, // Added rotation for realism
          {
            opacity: 1,
            y: 0,
            scale: 1,
            rotation: 0, // Reset rotation
            duration: 1.5,
            ease: 'bounce.out',
          }
        );

        gsap.fromTo(
          hRef.current,
          { opacity: 0, y: 50, scale: 0.9 }, // Added scale for depth effect
          {
            opacity: 1,
            y: 0,
            scale: 1, // Reset scale
            duration: 1.5,
            ease: 'power3.out',
          }
        );
      };

      const animateExclamation = () => {
        gsap.fromTo(
          exclamationRef.current,
          { scale: 1, rotation: 0 }, // Start without rotation
          {
            scale: 2,
            rotation: 10, // Add a little rotation
            duration: 1.2,
            ease: 'power1.inOut',
            onComplete: () => {
              gsap.to(exclamationRef.current, {
                scale: 1,
                rotation: 0, // Reset rotation
                duration: 0.6,
                ease: 'power1.out',
              });
            },
          }
        );
      };

      // Progress bar scroll animation
      const updateProgressBar = () => {
        const scrollTop = window.scrollY;
        const docHeight = document.documentElement.scrollHeight - window.innerHeight;
        const progress = (scrollTop / docHeight) * 100;

        gsap.to(progressRef.current, {
          width: `${progress}%`,
          duration: 0.2,
        });
      };

      // Add scroll event listener for progress bar
      window.addEventListener('scroll', updateProgressBar);

      // Initial animations
      animateText();
      animateExclamation();

      // Cleanup event listeners on component unmount
      return () => {
        window.removeEventListener('scroll', updateProgressBar);
      };
    }
  }, [loading]);

  return (
    <section id="hero" className="jumbotron" style={{ overflow: 'visible', position: 'relative' }}>
      {/* Progress Bar */}
      <div
        className="progress-bar"
        ref={progressRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '5px',
          backgroundColor: '#FF5733',
          width: '0%',
          zIndex: 9999, // Ensure it is on top
        }}
      ></div>

      <div className="container">
        <h1 className="hero-title" style={{ margin: '0' }}>
          <span ref={hiRef} className="text-color-main" style={{ display: 'inline-block', marginRight: '10px' }}>
            Hi
          </span>
          <span ref={exclamationRef} className="text-color-main" style={{ display: 'inline-block', marginRight: '10px' }}>
            !
          </span>
          <span ref={hRef} className="text-color-main" style={{ display: 'inline-block', marginLeft: '10px' }}>
            This is Thishanth
          </span>
          <br />
          <span style={{ display: 'block', marginTop: '10px' }}>I'm A WEB Developer.</span>
        </h1>
        <p className="hero-cta">
          <a rel="noreferrer" className="cta-btn cta-btn--hero" href="#about">
            Know more
          </a>
        </p>
      </div>

      {/* Floating icons */}
      <div className="floating-icons" style={{ position: 'absolute', bottom: '10%', left: '0', right: '0', display: 'flex', justifyContent: 'space-around', zIndex: 1 }}>
        <i className="fab fa-html5"></i>
        <i className="fab fa-css3-alt"></i>
        <i className="fab fa-js"></i>
        <i className="fab fa-react"></i>
        <i className="fab fa-github"></i>
      </div>
    </section>
  );
};

export default HeroSection;
