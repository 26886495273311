import React from 'react';
import ReactDOM from 'react-dom/client'; // Import createRoot from react-dom/client
import App from './components/App';
import './styles.scss';
import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

// Create a root and render the React App
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);
root.render(<App />);

// Initialize Scroll Reveal and Tilt Effect after rendering
const initEffects = () => {
    initScrollReveal(targetElements, defaultProps);
    initTiltEffect();
};

window.addEventListener('load', initEffects);
