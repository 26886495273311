import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer = () => {
  return (
    <footer className="footer navbar-static-bottom">
      <div className="container">
        <a rel="noreferrer" href="#top" className="back-to-top">
          <i className="fas fa-angle-up fa-2x" aria-hidden="true"></i>
        </a>
        <div className="social-links">
          {/* <a rel="noreferrer" href="#!" target="_blank">
            <i className="fab fa-twitter fa-inverse"></i>
          </a>
          <a rel="noreferrer" href="#!" target="_blank">
            <i className="fab fa-linkedin fa-inverse"></i>
          </a>
          <a rel="noreferrer" href="#!" target="_blank">
            <i className="fab fa-github fa-inverse"></i>
          </a> */}
        </div>

        <hr />

        <p className="footer__text">
          © 2024 - Enhanced by <strong>Thishanth</strong>
        </p>

        <p className="mt-3 pt-3">
          <a
            rel="noreferrer"
            className="github-button"
            href="https://github.com/cobiwave/simplefolio/fork"
            data-icon="octicon-repo-forked"
            data-size="large"
            data-show-count="true"
            aria-label="Fork ntkme/github-buttons on GitHub"
          >
         
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
