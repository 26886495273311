import React, { useState, useEffect } from 'react';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import ProjectsSection from './ProjectsSection';
import ContactSection from './ContactSection';
import Footer from './Footer';
import Loader from './Loader';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }, []);

  return (
    <div>
      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          <HeroSection loading={loading} />
          <AboutSection loading={loading} />
          <ProjectsSection loading={loading} />
          <ContactSection loading={loading} />
          <Footer loading={loading} />
        </>
      )}
    </div>
  );
};

export default App;
