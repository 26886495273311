import React, { useState, useEffect } from "react";
import profileImage from "../assets/profile.gif"; // Adjust the path as needed
import { Modal, Button, Spinner } from 'react-bootstrap';
import gsap from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import Font Awesome
import { faDownload } from '@fortawesome/free-solid-svg-icons'; // Import the download icon

const AboutSection = () => {
  const [show, setShow] = useState(false); // State to control the modal
  const [loading, setLoading] = useState(true); // State to control the loader

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // GSAP Animation effect on component mount
  useEffect(() => {
    gsap.from(".about-wrapper__image", {
      opacity: 0,
      scale: 0.8,
      duration: 1,
      ease: "back.out(1.7)"
    });

    gsap.from(".about-wrapper__info-text", {
      opacity: 0,
      x: -50,
      duration: 1,
      stagger: 0.3, // Staggering each point to appear one after another
      ease: "power2.out"
    });
  }, []);

  // Function to handle iframe load
  const handleIframeLoad = () => {
    setLoading(false); // Hide the loader once the iframe is fully loaded
  };

  return (
    <section id="about" className="about-section">
      <div className="container">
        <h2 className="section-title">About Me</h2>
        <div className="row about-wrapper">
          <div className="col-md-6 col-sm-12">
            <div className="about-wrapper__image">
              <img
                alt="Profile"
                className="img-fluid rounded shadow-lg"
                height="auto"
                width="300px"
                src={profileImage}
              />
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="about-wrapper__info">
              <p className="about-wrapper__info-text">
                I am a web developer with one year of experience, passionate about creating engaging online experiences.
              </p>
              <p className="about-wrapper__info-text">
                When I’m not coding, you can find me exploring new technologies or working on personal projects that challenge my skills.
              </p>
              <span className="d-flex mt-3">
                <Button variant="primary" onClick={handleShow}>
                  View Resume
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Embedded Resume Preview */}
      <Modal 
        show={show} 
        onHide={handleClose} 
        centered // Vertically center the modal
        size="lg" 
        dialogClassName="responsive-modal" 
      >
        <Modal.Header closeButton>
          <Modal.Title>My Resume</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Show loader while iframe is loading */}
          {loading && (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
          
          {/* Embed Google Drive Resume Preview */}
          <div style={{ textAlign: "center", display: loading ? 'none' : 'block' }}>
            <iframe 
              src="https://drive.google.com/file/d/1a_9Ai_G-LvDdP7yCjvlD5d1tk6Q9cj4X/preview" 
              width="100%"  
              height="auto" 
              allow="autoplay"
              style={{ border: 'none', minHeight: '500px' }} 
              title="Resume Preview"
              onLoad={handleIframeLoad} 
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => {
            window.open('https://drive.google.com/uc?id=1a_9Ai_G-LvDdP7yCjvlD5d1tk6Q9cj4X', '_blank'); // Change to the direct download link
          }}>
            <FontAwesomeIcon icon={faDownload} /> Download Resume
          </Button>
        </Modal.Footer>
      </Modal>

      <style>{`
        .about-section {
          padding: 50px 0;
        }

        .section-title {
          text-align: center;
          margin-bottom: 30px;
          font-size: 2.5rem;
          color: #333;
        }

        .about-wrapper__info-text {
          font-size: 1.3rem; 
          line-height: 1.8;  
          margin-bottom: 20px;
        }

        .btn {
          font-size: 1.1rem; 
        }

        /* Responsive iframe for resume */
        iframe {
          max-width: 100%;
          height: auto;
        }

        /* Custom Modal size for responsiveness */
        @media (min-width: 792px) {
          .responsive-modal .modal-dialog {
            max-width: 80vw; 
          }
          iframe {
            height: 1000px; 
          }
        }

        @media (max-width: 768px) {
          .responsive-modal .modal-dialog {
            max-width: 90vw; 
          }
          iframe {
            height: 600px;  
          }
        }

        @media (max-width: 576px) {
          .responsive-modal .modal-dialog {
            max-width: 95vw; 
          }
          iframe {
            height: 500px;  
          }
        }
      `}</style>
    </section>
  );
};

export default AboutSection;
